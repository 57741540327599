import { keyBy, sortBy, find, set } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Input, Tag } from 'antd';

import './BotTab.sass';
import { useState } from 'react';
import { BotMessage } from 'components/bot/BotMessage';

// Props
interface GeneralTabProps {
    messages: any[];
    onSendMessage: Function
}

export const BotTab = (props: GeneralTabProps) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSendMessage = (callback) => {
        setLoading(true);
        props.onSendMessage(message, callback);
        setMessage("");
    }

    return (
        <>
            <div className="BotTab">

                <div className="BodyWrapper">
                    {props.messages.map((message, index) => {
                        return (
                            <BotMessage key={index} message={message}>

                            </BotMessage>
                        )
                    })}
                    {loading && <BotMessage loading={true} message={{ role: "assistant" }}></BotMessage>}
                </div>
                <div className='InputWrapper pt-1 pb-05'>
                    <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        style={{ marginBottom: 10 }}
                        className='input'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder='Enter your question here'
                    />
                    <Button onClick={(message) => handleSendMessage(() => {
                        setLoading(false)
                    })} className="w-100" type="primary" style={{ marginBottom: 10 }}>{t('actions.send')}</Button>
                </div>

            </div>
        </>
    );
};

export default BotTab;
