import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const listProjects = gql`
  query ListProjects {
    listProjects {
      items {
        id
        reference
        name
        description
        createdAt
        type
      }
      
    }
  }
`;

export function useListProjectsQuery() {
  return Apollo.useQuery(listProjects);
}

export const askBot = gql`
  query askBot($projectId: String!, $question: String!, $threadId: String, $scenarioId: String, $screen: String) {
    askBot(projectId: $projectId, question: $question, threadId: $threadId, scenarioId: $scenarioId, screen: $screen) {
      answer
      items {
        name
        type
      }
      threadId
    }
  }
`;

const subscribeToChanges = gql`
  subscription SubscribeToChanges($projectId: String!) {
    onProjectChange(projectId: $projectId) {
      projectId
      changes {
        objectId
        type
        operation
        field
      }
    }
  }
`;

export function useChangeSubscription(projectId: string) {
  return Apollo.useSubscription(subscribeToChanges, {
    variables: {
      projectId
    }
  });
}