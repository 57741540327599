export default {
	form: {
		required_error: 'This field is required',
		max_length_exceeded_error:
			'This field is too long ({{length}} characters max)'
	},
	legal: {
		i_consent: 'I consent',
		agreement_title: 'Agreement',
		agreement_content:
			'By using Gerositus, you agree to our <1>Terms & conditions</1> and <2>Privacy Policy</2>.',
		agreement_terms_conditions: 'Terms & conditions',
		agreement_privacy_policy: 'Privacy Policy'
	},
	yes: 'Yes',
	no: 'No',
	not_defined: 'Not defined',
	please_wait: 'Please wait'
};
