// @ts-nocheck
import React, { Component, useEffect, useState } from 'react';

import UIContext from './UIContext';
import { set } from 'lodash';
import { useAskBotQuery } from 'graph/dist/generated';
import { useApolloClient } from '@apollo/client';
import { askBot } from 'graph/queries';

const defaultPreferences = {
	global: {},
	pages: {
		navigator: {
			links: false
		},
		risks: {
			sortOrder: 'ascend',
			sortBy: 'id',
			columns: {
				evaluatedAt: true,
			}
		},
		indicators: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		constraints: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		fishbone: {

		}
	}
};

const UIProvider = ({ children }) => {

	const [ready, setReady] = useState(false);
	const apolloClient = useApolloClient();

	// AI
	const [botThreadId, setBotThreadId] = useState(null);
	const [botThreadMessages, setBotThreadMessages] = useState([]);

	const [preferences, setPreferences] = useState({
		...defaultPreferences
	});

	const [selection, setSelection] = useState(null);

	const addBotMessage = async (projectId, scenarioId, screen, message, callback) => {

		const newMessages = [...botThreadMessages, { role: "user", message }];
		setBotThreadMessages(newMessages);

		const variables = {
			projectId,
			question: message,
			threadId: botThreadId || null,
			scenarioId: scenarioId || null,
			screen: screen || null
		};

		const result = await apolloClient.query({
			query: askBot,
			variables
		});
		setBotThreadMessages(
			[
				...newMessages,
				{
					role: "assistant",
					...result.data.askBot
				}
			]
		);
		if (result.data.askBot.threadId) {
			setBotThreadId(result.data.askBot.threadId);
		}
		if (callback) callback();
	}

	const resetBotThread = () => {
		setBotThreadMessages([]);
		setBotThreadId(null);
	}

	useEffect(() => {
		const storedPreferences = localStorage.getItem('gerositus.preferences');
		let parsedPreferences = {}
		try {
			parsedPreferences = storedPreferences ? JSON.parse(storedPreferences) : {};
		} catch (e) {
			console.error('Error parsing preferences', e);
			localStorage.removeItem('gerositus.preferences');
		}

		setPreferences(
			{
				...defaultPreferences,
				...parsedPreferences
			}
		);
		setReady(true);
	}, []);

	const setSpecificPreferences = async (type, preference, value) => {
		console.log('setSpecificPreferences', type, preference, value);
		const newPreferences = set(preferences, `${type}.${preference}`, value);
		setPreferences({ ...newPreferences });
		localStorage.setItem('gerositus.preferences', JSON.stringify(newPreferences));
	};

	const select = async (obj, callback) => {
		await setSelection(obj);
		callback && callback();
	};

	const deselect = async (callback) => {
		await setSelection(null);
		callback && callback();
	};

	return <UIContext.Provider value={{
		selection,
		select: select,
		deselect: setSelection,
		preferences,
		setPreferences: setSpecificPreferences,
		botThread: {
			id: botThreadId,
			messages: botThreadMessages
		},

		setBotThreadId,
		addBotMessage,
		resetBotThread

	}}>
		{ready ? children : null}
	</UIContext.Provider >


}

export default UIProvider;